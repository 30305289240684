import "../styles/posts.css";
import image from "../../../assets/images/programs.jpg";
import ContactForm from "../../contact-form/contactForm";
import { Helmet } from "react-helmet";

export default function Programs() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img
					src={image}
					alt="company consulting"
					className="singlePostImg width-600"
				/>
				<h1 className="singlePostTitle">Програми</h1>
				<Helmet>
					<title>Програми</title>
					<meta
						name="description"
						content="Да се науча да поставям лични граници в общуването с хората и света около мен, 
						беше една от най- трудните задачи в моя път на лична трансформация. Беше изумително за мен, 
						когато разбрах, че една от най- трудните думи за мен е “НЕ”. Сякаш езика ми не можеше да се пречупи. 
						Как аз направих това? С помощта на кого и какво? 
						Отговорът ще получите, ако се включите в есномесечната програма, която стартира на 01.07.2024 г. "
					/>
				</Helmet>
				<div className="singlePostDesc">
					<p>
						Да се науча да поставям лични граници в общуването с
						хората и света около мен, беше една от най- трудните
						задачи в моя път на лична трансформация. Беше изумително
						за мен, когато разбрах, че една от най- трудните думи за
						мен е “НЕ”. Сякаш езика ми не можеше да се пречупи.
					</p>
					<p>Как аз направих това?</p>
					<p>С помощта на кого и какво?</p>
					<p>
						Отговорът ще получите, ако се включите в есномесечната
						програма, която стартира на 01.07.2024 г. Отговорът ще
						получите, ако се включите в есномесечната програма,
						която стартира на 01.07.2024 г.
					</p>
					<p>“Постави граници и заживей във вътрешен мир”</p>
					<p className="bold pd-10">ОПРЕДЕЛЕНИЕ ЗА ЛИЧНИ ГРАНИЦИ</p>
					<p>
						Границите представляват очаквания и потребности,
						благодарение на които можете да се чувствате сигурни и
						спокойни в отношенията си с околните. Очакванията
						допринасят за психическото и емоционалното ви здраве.
						Развиването на усета кога да кажете „не" и кога „да" е
						жизненоважно, ако искате да общувате без смущение.
					</p>
					<p className="pt-10">Смисълът на границите:</p>
					<ul>
						<li className="tick-shaped">
							Служат като предпазна мярка срещу прекомерното
							себераздаване.
						</li>
						<li className="tick-shaped">
							Представляват форма на грижа за себе си.
						</li>
						<li className="tick-shaped">
							Определят ролите във взаимоотношенията.
						</li>
						<li className="tick-shaped">
							Дефинират допустимо и недопустимо поведение въВ
							взаимоотношенията.
						</li>
						<li className="tick-shaped">
							Задават очакванията във взаимоотношенията.
						</li>
						<li className="tick-shaped">
							Помагат ви да отправяте молба към другите да се
							съобразяват с потребностите ви. Помагат ви да
							изразявате по требностите си.
						</li>
						<li className="tick-shaped">
							Помагат ви да градите здравословни отношения.
						</li>
						<li className="tick-shaped">
							Помагат ви да постигате яснота.
						</li>
						<li className="tick-shaped">
							Дават ви усещане за сигурност.
						</li>
					</ul>
					<p className="bold pd-10">
						КАКВО МОЖЕ ДА СЕ СЛУЧИ, КОГАТО НЕ НАЛАГАМЕ ГРАНИЦИ
					</p>
					<p className="pb-10">😓Изтощение</p>

					<p>
						Изтощението е смазващо и лекут за него е очертаването на
						граници. До изтощение се стига, когато човек изпитва
						редовно емоционална, умствена или физическа преумора. В
						много случаи води до чувство на раздразнено безсилие, до
						занемаряване на задълженията, до чести промени в
						настроението и до отбягване.
					</p>
					<p>
						Според Емили и Амилия Нагоски, авторките на „Изтощение.
						Как да излезем от порочния кръг на стреса", изтощението
						се дължи на стреса, който те определят като
						„неврологичните и физиологичните промени, които
						настъпват в организма, когато се сблъсква с
						“дразнители".
					</p>
					<p>Човек стига до изтощение, ако:</p>
					<ul>
						<li className="tick-shaped">
							не знае кога да каже „не";
						</li>
						<li className="tick-shaped">
							не знае как да каже ,не ;
						</li>
						<li className="tick-shaped">
							поставя другите, а не себе си, на прво място стреми
							се винаги да им угажда; страда от синдрома на
							супергероя „Мога да всичко";
						</li>
						<li className="tick-shaped">
							храни нереалистични очаквания;
						</li>
						<li className="tick-shaped">
							усилията му не биват оценявани.
						</li>
					</ul>

					<p>
						Сложете край на терзанията, изразявайте на глас своите
						потребности и вкусете свободата да бъдете наистина себе
						си.
					</p>
					<p>
						Включете се в едномесечната програма “Постави граници и
						заживей във вътрешен мир”
					</p>
					<p>Какво ще получите в програмата:</p>
					<p>1. 14 лекции на тема:</p>
					<p className="pt-10">Част Първа</p>
					<ul>
						<li className="tick-shaped">
							Какво означава здравословна граница?
						</li>
						<li className="tick-shaped">
							Какво ни струва липсата на здравословни граници
						</li>
						<li className="tick-shaped">
							Защо нямаме здравословни граници?
						</li>
						<li className="tick-shaped">Шестте вида граници</li>
						<li className="tick-shaped">
							Как изглеждат нарушените граници
						</li>
						<li className="tick-shaped">
							Разпознавайте и заявявайте границите си
						</li>
						<li className="tick-shaped">
							Замъглените граници. Нека ги направим кристално ясни
						</li>
						<li className="tick-shaped">Травми и граници</li>
						<li className="tick-shaped">
							По какъв начин зачитате своите граници
						</li>
					</ul>
					<p>Част 2</p>
					<ul>
						<li className="tick-shaped">Семейството и границите</li>
						<li className="tick-shaped">
							Романтичните отношения и границите
						</li>
						<li className="tick-shaped">
							Приятелството и границите
						</li>
						<li className="tick-shaped">Работата и границите</li>
						<li className="tick-shaped">
							Социални медии и технологии
						</li>
					</ul>
					<p>
						2. Дискусионен лайф веднъж седмично с мен, в рамките на
						1 час, в който ще давам практически упражнения.
					</p>
					<p>
						3. Чат канал за дискусии с участниците в програмата в
						телеграм.
					</p>
				</div>
			</div>
			<div className="contact-form-container">
				<h3>Изпратете запитване</h3>
				<ContactForm />
			</div>
		</div>
	);
}
